<template>
  <div id="cesiumContainer">
    <div class="base-box">
      <div class="base-wrapper">
        <el-tooltip v-if="false" placement="left" content="工具箱">
          <template slot="title">
            <span>工具箱</span>
          </template>
          <div class="layer common" @click="boxHandler('tool')">
            <img :class="boxCtrl == 'tool' ? 'hilight' : ''" src="@/assets/images/map/tool.png" />
          </div>
        </el-tooltip>
        <el-tooltip v-if="false" placement="left" content="图层">
          <template slot="title">
            <span>图层</span>
          </template>
          <div class="layer common" @click="boxHandler('layer')">
            <img :class="boxCtrl == 'layer' ? 'hilight' : ''" src="@/assets/images/map/layer.png" />
          </div>
        </el-tooltip>
        <el-tooltip placement="left" content="定位">
          <template slot="title">
            <span>定位</span>
          </template>
          <div class="location common" @click="setAction()">
            <img src="@/assets/images/map/location.png" />
          </div>
        </el-tooltip>
        <el-tooltip placement="left" content="放大">
          <template slot="title">
            <span>放大</span>
          </template>
          <div class="zoomin common" @click="setAction(1)">
            <img src="@/assets/images/map/zoomin.png" />
          </div>
        </el-tooltip>
        <el-tooltip placement="left" content="缩小">
          <template slot="title">
            <span>缩小</span>
          </template>
          <div class="zoomout common" @click="setAction(-1)">
            <img src="@/assets/images/map/zoomout.png" />
          </div>
        </el-tooltip>
        <el-tooltip placement="left" :content="sceneMode==1?'3D':'哥伦布视图'">
          <template slot="title">
            <span>{{sceneMode==1?'3D':'哥伦布视图'}}</span>
          </template>
          <div class="zoomout common" @click="toColumbus">
            <img v-if="sceneMode===3" src="@/assets/images/map/columbus.png"/>
            <img v-else src="@/assets/images/map/3d.png"/>
          </div>
        </el-tooltip>
        <el-tooltip placement="left" content="2D">
          <template slot="title">
            <span>2D</span>
          </template>
          <div class="zoomout common" @click="to2D">
            <img src="@/assets/images/map/2d.png" />
          </div>
        </el-tooltip>

        
        
        <!-- <div v-show="boxCtrl == 'layer'" class="layer-box">
          <el-collapse v-model="activeName" accordion>
            <template v-for="(layer, index) in layers">
              <el-collapse-item :title="layer.name" :key="'layer_' + idnex" :name="index">
                <template v-for="(layer1, idx) in layer.layers">
                  <div class="switch-item">
                    <div>{{ layer1.name }}</div>
                    <div>
                      <el-switch v-model="layer1.show" active-color="#13ce66" @change="cahngeLayer(layer1)"></el-switch>
                    </div>
                  </div>
                </template>
              </el-collapse-item>
            </template>
          </el-collapse>
        </div> -->

        <!-- <div v-show="boxCtrl == 'tool'" class="layer-box">
          <div class="base-layer-box">
            <div class="title">选择工具</div>
            <div v-for="item in tools" class="tool-item" :class="calcDraw == item.value ? 'is-drawing' : ''" @click="startGeometryByType(item.value)">
              <img :src="calcDraw == item.value ? item.imgS : item.img" />
              {{ item.name }}
              <div class="spot" v-if="calcDraw == item.value"></div>
            </div>

            <div class="tool-item" @click="clearDrawedGeometries()">
              <img src="@/assets/images/map/tool/point.png" />
              清除
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import mapMixin from './mapMixin';

import { Viewer, SceneMode,createWorldTerrain, Cartesian3, PolylineColorAppearance, PolylineMaterialAppearance, HorizontalOrigin, VerticalOrigin, JulianDate } from 'cesium/Cesium';
import { HeadingPitchRange, LabelStyle, Math, Cartesian2, UrlTemplateImageryProvider, Primitive, GeometryInstance, PolylineGeometry, Matrix4, Material, PolylineArrowMaterialProperty,PolylineDashMaterialProperty,Color, Model, Transforms, HeadingPitchRoll, Cesium3DTileset, Cartographic, BoundingSphere, ClippingPlaneCollection, ClippingPlane } from 'cesium/Cesium';

import {FeatureDetection} from 'cesium/Cesium'; 
import coordtransform from '@/utils/coordtransform.js'; //坐标转换
import { TDTTK, ORIGIN_LONGLAT, ROUTE, LAYER, CESIUM_PARAM } from './map.config.js';
import 'cesium/Widgets/widgets.css';

import M300Glb from '../../../public/static/model/M300.glb'; //uav.gltf
import MK5Glb from '../../../public/static/model/MK5.glb';
import MK5OpenedGlb from '../../../public/static/model/MK5_opened.glb';
import { SITE_HIVE_TYPE } from '../../enum/SITE_HIVE_TYPE';

const M300GlbHeadOffset = 170; //  模型偏移
const ID = {
  laser: 'laser',
  realTimePath: 'realTimePath',
};

export default {
  name: 'CesiumMap',
  props: {
    pts: {
      type: Array,
      default() {
        return [];
      },
    },

    uavPos: {
      type: Object,
      default() {
        return {};
      },
    },

    records: {
      type: Array,
      default() {
        return [];
      },
    },
    trackUAV: {
      type: Boolean,
      default() {
        return false;
      },
    },
    site: {
      type: Object,
      default() {
        return {};
      },
    },
    laser: {
      type: Array,
      default() {
        return [];
      },
    },
    endSite: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mixins: [mapMixin],
  data() {
    return {
      sceneMode:3,
      zooms: [
        { title: '放大', value: 1, img: require('@/assets/images/map/zoomin.png') },
        { title: '缩小', value: -1, img: require('@/assets/images/map/zoomout.png') },
        { title: '定位', value: 0, img: require('@/assets/images/map/location.png') },
      ],
      imgs: {
        pt: '',
      },
      globalRoute: {
        ptEntities: [],
        ptPrimitives: [],
        lineEntity: null,
        lineDashPrimitive: null,
      },

      siteID: 'siteID',
      endSiteID: 'endSiteID',
    };
  },
  mounted() {
    // window.Cesium.Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1NWI5MGUzNi1mYWI3LTQzY2QtOGI0Ni0xZWYyNTAxNGM4N2MiLCJpZCI6MTI1OTgsInNjb3BlcyI6WyJhc3IiLCJnYyJdLCJpYXQiOjE1NjE0NDkyNTV9.hBH0PGSnKErc_yNhIePASUkr3QPDoo0KDX9uLpNBUns';
    window.UAVModel = null;
    window.cesiumTrailPath = null;
    window.cesiumTrailPathArr = [];
    this.initCesiumData();
  },

  beforeDestroy() {
    this.clearData();
    if (window.viewer && !window.viewer.isDestroyed()) {
      window.viewer.destroy();
    }
    window.viewer = null;
  },
  watch: {
    site: {
      deep: true,
      handler() {
        this.addSite();
        this.updateRoute(this.pts);
      },
    },
    laser: {
      handler(laserNew) {
        if (laserNew.length > 2) {
          this.addLaser(laserNew[0], laserNew[1], laserNew[2]);
        } else {
          this.removeLaser();
        }
      },
    },
    pts: {
      deep: true,
      handler(arr) {
        this.updateRoute(arr);
      },
    },

    uavPos: {
      deep: true,
      handler() {
        this.updateUavPos(this.uavPos);
      },
    },
    records: {
      deep: true,
      handler(arr) {
        if (window.viewer) {
          this.setRealTimePath(arr);
        }
      },
    },
    sceneMode(){
      if(window.viewer){
        window.viewer.scene.mode= this.sceneMode;
      }
    }
  },

  methods: {
    to2D() {
      this.$emit('twoD');
    },
    loadGrandCanyon() {
      // Pick a position at the Grand Canyon
      const globe = window.viewer.scene.globe;
      const viewer = window.viewer;
      const position = new Cartographic.toCartesian(new Cartographic.fromDegrees(this.site.siteLocation[0] + 0.00003, this.site.siteLocation[1], this.site.altitude));
      const distance = 3000.0;
      const boundingSphere = new BoundingSphere(position, distance);

      globe.clippingPlanes = new ClippingPlaneCollection({
        modelMatrix: Transforms.eastNorthUpToFixedFrame(position),
        planes: [new ClippingPlane(new Cartesian3(1.0, 0.0, 0.0), distance), new ClippingPlane(new Cartesian3(-1.0, 0.0, 0.0), distance), new ClippingPlane(new Cartesian3(0.0, 1.0, 0.0), distance), new ClippingPlane(new Cartesian3(0.0, -1.0, 0.0), distance)],
        unionClippingRegions: true,
        edgeWidth: 1.0,
        edgeColor: Color.WHITE,
        enabled: true,
      });
      globe.backFaceCulling = false;
      globe.showSkirts = false;

      viewer.camera.viewBoundingSphere(boundingSphere, new HeadingPitchRange(0.5, -0.5, boundingSphere.radius * 5.0));
      viewer.camera.lookAtTransform(Matrix4.IDENTITY);
    },

    initCesiumData() {
      this.$nextTick(() => {
        window.viewer = new Viewer('cesiumContainer', {
          geocoder: false,
          terrainProvider: createWorldTerrain(),
          homeButton: false,
          sceneMode:this.sceneMode,
          sceneModePicker: false,
          baseLayerPicker: false,
          navigationHelpButton: false,
          infoBox: false, //右上角的信息框
          animation: false, //左下角的动画控件的显示
          shouldAnimate: false, //控制模型动画
          timeline: false,
          fullscreenButton: false,

          imageryProvider: new UrlTemplateImageryProvider({
            url: CESIUM_PARAM.base,
            layer: 'img_w', //WMTS请求的层名称
            style: 'default', //WMTS请求的样式名称
            format: 'tiles', //MIME类型，用于从服务器检索图像
            tileMatrixSetID: 'GoogleMapsCompatible', //    用于WMTS请求的TileMatrixSet的标识符
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            minimumLevel: 0, //最小层级
            maximumLevel: 18, //最大层级
          }),
        });
        window.viewer.imageryLayers.addImageryProvider(
          new UrlTemplateImageryProvider({
            url: CESIUM_PARAM.mark,
            layer: 'img_w', //WMTS请求的层名称
            style: 'default', //WMTS请求的样式名称
            format: 'tiles', //MIME类型，用于从服务器检索图像
            tileMatrixSetID: 'GoogleMapsCompatible', //    用于WMTS请求的TileMatrixSet的标识符
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6', 't7'],
            minimumLevel: 0, //最小层级
            maximumLevel: 18, //最大层级
          }),
        );
       // window.viewer.scene.globe.depthTestAgainstTerrain = true;
        //麦腾 3D模型建筑 测试
        if (this.site && this.site.siteID === 'ST_00000017') {
          viewer.scene.primitives.add(
            new Cesium3DTileset({
              url: CESIUM_PARAM.BIM_BASE + 'jinqiao3D/terra_b3dms/tileset.json',
              show: true,
              maximumScreenSpaceError: 1,
            }),
          );
        }

        if (this.site && (this.site.siteID === 'ST_00000109' ||this.site.siteID === 'SITE_1667296266827') ) {
          // 滨江三维模型一测试
          viewer.scene.primitives.add(
            new Cesium3DTileset({
              url: CESIUM_PARAM.BIM_BASE + 'terrain_binjiang/binjiang1/terra_b3dms/tileset.json',
              show: true,
              maximumScreenSpaceError: 1,
            }),
          );
          viewer.scene.primitives.add(
            new Cesium3DTileset({
              url: CESIUM_PARAM.BIM_BASE + 'terrain_binjiang/binjiang2/terra_b3dms/tileset.json',
              show: true,
              maximumScreenSpaceError: 1,
            }),
          );
          viewer.scene.primitives.add(
            new Cesium3DTileset({
              url: CESIUM_PARAM.BIM_BASE + 'terrain_binjiang/binjiang3/terra_b3dms/tileset.json',
              show: true,
              maximumScreenSpaceError: 1,
            }),
          );

          viewer.scene.primitives.add(
            new Cesium3DTileset({
              url: CESIUM_PARAM.BIM_BASE + 'terrain_binjiang/binjiang4/terra_b3dms/tileset.json',
              show: true,
              maximumScreenSpaceError: 1,
            }),
          );
        }

        if(FeatureDetection.supportsImageRenderingPixelated()){ 
          window.viewer.resolutionScale = window.devicePixelRatio;
        }
      //  window.viewer.scene.postProcessStages.fxaa.enabled = true; // 抗锯齿

        window.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 0;
        window.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 50000000;
        window.viewer._cesiumWidget._creditContainer.style.display = 'none'; // 去除版权信息
        if (this.site) {
          this.addSite();
        }
        if (this.endSite) {
          this.addSite(true);
        }
        if (this.pts.length > 0) {
          this.updateRoute(this.pts);
        }
        if (this.uavPos) {
          this.updateUavPos(this.uavPos);
        }

        if (this.records.length > 0) {
          this.setRealTimePath(this.records);
        }

        this.$emit('rendered');
        this.flyToCenter();

        // 测试代码 todo！！！  测试无人机模型

        // let lngTest = this.site.siteLocation[0],
        //   latTest = this.site.siteLocation[1],
        //   htTest = 50;
        // this.updateUavPos({
        //   position: [lngTest, latTest, htTest],
        //   head: -90,
        //   pitch: 0,
        //   roll: 0
        // });

        // this.loadGrandCanyon();
      });
    },

    clearData() {
      //  this.clearRoute();
      //  this.removeLaser();

      if (window.viewer) {
        if (window.viewer.entities && window.viewer.entities.length > 0) {
          window.viewer.entities.forEach((en) => {
            if (en._id != this.ID.siteID && en.xl) {
              window.viewer.entities.remove(en);
            }
          });
        }
        if (window.viewer.primitives && window.viewer.primitives.length > 0) {
          window.viewer.primitives.forEach((en) => {
            if (en.xl) {
              window.viewer.primitives.remove(en);
            }
          });
        }
      }
      window.UAVModel = null;
      window.cesiumTrailPath = null;
      window.cesiumTrailPathArr = [];
    },

    // 清除飞行数据
    clearFlightData() {
      this.clearData();
    },
   
    // 飞到初始点
    flyToCenter() {
      window.viewer.flyTo(window.viewer.entities);
    },

    setAction(t) {
      switch (t) {
        case 1: // 放大
          window.viewer.camera.zoomIn();
          break;
        case -1: // 缩小
          window.viewer.camera.zoomOut();
          break;
        default:
          // window.viewer.camera.flyHome();
          this.flyToCenter();
      }
    },

    // 添加站点
    addSite(end) {
      let id = end ? this.endSiteID : this.siteID;
      let site = end ? this.endSite : this.site;
      if (window.viewer.entities.getById(id)) {
        window.viewer.entities.remove(window.viewer.entities.getById(id));
      }

      if (site && site.siteLocation) {
        let lng = site.siteLocation[0],
          lat = site.siteLocation[1],
          alt = site.siteAltitude;
        // 经纬度转换
        // let gpslngLatTmp = coordtransform.wgs84togcj02(lng, lat);
        let gpslngLatTmp = [lng, lat];
        lng = gpslngLatTmp[0];
        lat = gpslngLatTmp[1];
        let siteMode = site.siteMode;
        let hiveModel = null;
        if (site.hiveInfo) {
          hiveModel = site.hiveInfo && site.hiveInfo.hiveModel ? site.hiveInfo.hiveModel : '未知';
        }

        //  const position = Cartesian3.fromDegrees(lng+0.00005,lat-0.000005,alt+3.5);
        const position = Cartesian3.fromDegrees(lng, lat, alt + 3.5);
        let en = null;
        if (hiveModel && hiveModel === SITE_HIVE_TYPE.HIVEMODEL.UH_MK5) {
          const head = Math.toRadians(-20);
          const pitch = Math.toRadians(0);
          const roll = Math.toRadians(0);
          const hpr = new HeadingPitchRoll(head, pitch, roll);
          const orientation = Transforms.headingPitchRollQuaternion(position, hpr);
          en = window.viewer.entities.add({
            id: id,
            name: MK5Glb,
            position: position,
            orientation: orientation,
            model: {
              uri: MK5Glb, //
              minimumPixelSize: 50,
              maximumScale: 200000,
              scale: 0.14,
            },
          });
        } else {
          en = window.viewer.entities.add({
            id: id,
            _id: id,
            position: position,
            label: {
              //文字标签
              text: site.siteName,
              font: '10pt monospace',
              style: LabelStyle.FILL_AND_OUTLINE,
              outlineWidth: 2,
              verticalOrigin: VerticalOrigin.BOTTOM, //垂直方向以底部来计算标签的位置
              pixelOffset: new Cartesian2(0, 0), //偏移量
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              show: true,
            },
            billboard: {
              image: this.getHivePicBysitemModeAndHivType(siteMode, hiveModel, false),
              horizontalOrigin: HorizontalOrigin.CENTER,
              verticalOrigin: VerticalOrigin.BOTTOM,
              width: 50,
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              height: 50,
            },
          });
        }
        en.xl = true;
      }
    },

    removeSite(end) {
      let id = end ? this.endSiteID : this.siteID;
      if (id) {
        window.viewer.entities.getById(id) && window.viewer.entities.remove(window.viewer.entities.getById(id));
      }
    },

    // 添加站点
    addLaser(lng, lat, alt) {
      let id = ID.laser;
      // 经纬度转换
      // let gpslngLatTmp = coordtransform.wgs84togcj02(lng, lat);
      let gpslngLatTmp = [lng, lat];
      lng = gpslngLatTmp[0];
      lat = gpslngLatTmp[1];
      const position = Cartesian3.fromDegrees(lng, lat, alt);
      const laserPt = window.viewer.entities.getById(id);
      if (window.laserPt) {
        window.laserPt.position = position;
      } else {
        window.laserPt = window.viewer.entities.add({
          id: id,
          _id: id,
          position: position,
          label: {
            //文字标签
            text: '激光测距目标物',
            font: '10pt monospace',
            style: LabelStyle.FILL_AND_OUTLINE,
            outlineWidth: 2,
            verticalOrigin: VerticalOrigin.BOTTOM, //垂直方向以底部来计算标签的位置
            pixelOffset: new Cartesian2(0, 0), //偏移量
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            show: true,
          },
          billboard: {
            image: this.ptImg.laser,
            horizontalOrigin: HorizontalOrigin.CENTER,
            verticalOrigin: VerticalOrigin.BOTTOM,
            width: 50,
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            height: 50,
          },
        });
        window.laserPt.xl = true;
      }
    },

    removeLaser() {
      if (window.laserPt) {
        window.viewer.entities.remove(window.laserPt);
      }
      window.laserPt = null;
    },

    // 绘制航线
    // 组成：点，点地面线，航线（可能包含站点），虚线
    updateRoute(pts, id = 'route') {
      this.globalRoute.pts = pts;
      let degreesArrayHeights = [];
      this.clearRoute();
      //  window.viewer.scene.primitives.removeAll();
      if (pts.length > 0) {
        // trails.push({ longitude: pts[0].lng, lat: pts[0].lat, height: 0, time: 0 });
        //先画点

        for (let i = 0; i < pts.length; i++) {
          let pt = pts[i];
          let lng = pt.longitude,
            lat = pt.latitude,
            alt = pt.altitude;

          // 经纬度转换
          // let gpslngLatTmp = coordtransform.wgs84togcj02(lng, lat);
          let gpslngLatTmp = [lng, lat];
          lng = gpslngLatTmp[0];
          lat = gpslngLatTmp[1];
          let entity = window.viewer.entities.add({
            id: id + i,
            _id: id + i,
            position: Cartesian3.fromDegrees(lng, lat, alt),

            label: {
              //文字标签
              text: '航点' + parseInt(i + 1),
              font: '10pt monospace',
              style: LabelStyle.FILL_AND_OUTLINE,
              outlineWidth: 2,
              verticalOrigin: VerticalOrigin.BOTTOM, //垂直方向以底部来计算标签的位置
              pixelOffset: new Cartesian2(0, 10), //偏移量
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              show: true,
            },
          });
          entity.xl = true;
          entity.lng = lng;
          entity.lat = lat;
          entity.alt = alt;

          this.globalRoute.ptEntities.push(entity);
          // 添加地面线
          let arrs = [];
          arrs.push(lng, lat, alt);
          arrs.push(lng, lat, 0);
          let primitive1 = new Primitive({
            geometryInstances: new GeometryInstance({
              geometry: new PolylineGeometry({
                positions: Cartesian3.fromDegreesArrayHeights(arrs),
                width: CESIUM_PARAM.line_width,
                // vertexFormat: PolylineColorAppearance.VERTEX_FORMAT,
              }),
            }),
            appearance: new PolylineMaterialAppearance({
              material: Material.fromType(Material.PolylineDashType, {
                color: Color.DEEPSKYBLUE.withAlpha(CESIUM_PARAM.line_alpha), //线条颜色
                gapColor: Color.TRANSPARENT, //间隔颜色
                dashLength: 10, //短划线长度
              })
            }),
          });
          primitive1.xl = true;
          this.globalRoute.ptPrimitives.push(window.viewer.scene.primitives.add(primitive1));

          degreesArrayHeights.push(lng, lat, alt);
        }

        // 机库和最后一个航点连线
        let lng = pts[pts.length - 1].longitude,
          lat = pts[pts.length - 1].latitude,
          alt = pts[pts.length - 1].altitude;

        // let temp = coordtransform.wgs84togcj02(lng, lat);
        let temp = [lng, lat];
        lng = temp[0];
        lat = temp[1];
        let siteHas = false,
          slng = 0,
          slat = 0,
          sAlt = 0;
        if (this.endSite && this.endSite.siteLocation) {
          siteHas = true;
          //  let gpslngLatS = coordtransform.wgs84togcj02(this.endSite.siteLocation[0], this.endSite.siteLocation[1]);
          let gpslngLatS = [this.endSite.siteLocation[0], this.endSite.siteLocation[1]];
          slng = gpslngLatS[0];
          slat = gpslngLatS[1];
          sAlt = this.endSite.siteAltitude;
        } else if (this.site && this.site.siteLocation) {
          siteHas = true;
          //  let gpslngLatS = coordtransform.wgs84togcj02(this.site.siteLocation[0], this.site.siteLocation[1]);
          let gpslngLatS = [this.site.siteLocation[0], this.site.siteLocation[1]];
          slng = gpslngLatS[0];
          slat = gpslngLatS[1];
          sAlt = this.site.siteAltitude;
        }
        if (siteHas) {
          degreesArrayHeights.unshift(slng, slat, pts[0].altitude);
          degreesArrayHeights.unshift(slng, slat, sAlt);
        }
        // 主航线
        this.globalRoute.lineEntity = window.viewer.entities.add({
          id: 'line3D',
          name: 'line3D',
          polyline: {
            positions: Cartesian3.fromDegreesArrayHeights(degreesArrayHeights),
            width: ROUTE.width,
            material: Color.YELLOW.withAlpha(1),
            depthFailMaterial:new PolylineDashMaterialProperty({
              color:  Color.YELLOW.withAlpha(0.5),
            })
          },
        });
        this.globalRoute.lineEntity.xl = true;

        if (siteHas) {
          let arrs = [slng, slat, sAlt, slng, slat, alt, lng, lat, alt];
          this.globalRoute.lineDashPrimitive = window.viewer.scene.primitives.add(
            new Primitive({
              geometryInstances: new GeometryInstance({
                geometry: new PolylineGeometry({
                  positions: Cartesian3.fromDegreesArrayHeights(arrs),
                  width: ROUTE.width,
                  // vertexFormat: PolylineColorAppearance.VERTEX_FORMAT,
                }),
              }),
              appearance: new PolylineMaterialAppearance({
                material: Material.fromType(Material.PolylineDashType, {
                  color: Color.YELLOW.withAlpha(CESIUM_PARAM.line_alpha), //线条颜色
                  gapColor: Color.TRANSPARENT, //间隔颜色
                  dashLength: 15, //短划线长度
                }),
              }),
            }),
          );
          this.globalRoute.lineDashPrimitive.xl = true;
        }
        this.flyToCenter();
      }
    },

    // 清除航线
    clearRoute() {
      //清除点
      if (this.globalRoute.ptEntities) {
        this.globalRoute.ptEntities.forEach((entity) => {
          window.viewer.entities.remove(entity);
        });
      }

      //清除xu线
      if (window.viewer && this.globalRoute.lineDashPrimitive) {
        window.viewer.scene.primitives.remove(this.globalRoute.lineDashPrimitive);
      }

      //清除地面线  window.viewer.scene.primitives
      if (window.viewer && this.globalRoute.ptPrimitives) {
        this.globalRoute.ptPrimitives.forEach((entity) => {
          window.viewer.scene.primitives.remove(entity);
        });
      }
      this.globalRoute.ptPrimitives = [];
      this.globalRoute.lineDashPrimitive = null;
      //清除主航线
      if (window.viewer && this.globalRoute.lineEntity) {
        window.viewer.entities.remove(this.globalRoute.lineEntity);
        this.globalRoute.lineEntity = null;
      }
    },

    // 根据原始航线（不包含连接）点更新航线（更新点，地面线，航线，虚线）
    updateRouteByIndexPt(index, pt) {
      if (pt && index >= 0 && index < this.globalRoute.ptEntities.length) {
        //   let trans = coordtransform.wgs84togcj02(pt.longitude, pt.latitude);
        let trans = [pt.longitude, pt.latitude];
        let lng = trans[0],
          lat = trans[1],
          alt = pt.altitude;
        //点
        this.globalRoute.ptEntities[index] && (this.globalRoute.ptEntities[index].position = Cartesian3.fromDegrees(lng, lat, alt));
        this.globalRoute.ptEntities[index].lng = pt.longitude;
        this.globalRoute.ptEntities[index].lat = pt.latitude;
        this.globalRoute.ptEntities[index].alt = pt.alt;
        // 地面线
        window.viewer.scene.remove(this.globalRoute.ptPrimitives[index]);
        let primitive = new Primitive({
          geometryInstances: new GeometryInstance({
            geometry: new PolylineGeometry({
              positions: Cartesian3.fromDegreesArrayHeights([lng, lat, alt, lng, lat, 0]),
              width: CESIUM_PARAM.line_width,
            }),
          }),
          appearance: new PolylineMaterialAppearance({
            material: Material.fromType(Material.PolylineDashType, {
              color: Color.DEEPSKYBLUE.withAlpha(CESIUM_PARAM.line_alpha), //线条颜色
              gapColor: Color.TRANSPARENT, //间隔颜色
              dashLength: 10, //短划线长度
            }),
          }),
        });
        window.viewer.scene.add(primitive);
        this.globalRoute.ptPrimitives.splice(index, 1, primitive);

        //主航线
        let degreesArrayHeights = [];
        // 第一个航点
        if (this.site && this.site.siteLocation) {
          // 经纬度转换
          // let gpslngLatTmp = coordtransform.wgs84togcj02(this.site.siteLocation[0], this.site.siteLocation[1]);
          let gpslngLatTmp = [this.site.siteLocation[0], this.site.siteLocation[1]];
          let slng = gpslngLatTmp[0],
            slat = gpslngLatTmp[1];
          degreesArrayHeights.push(slng, slat, this.site.siteAltitude);
        }
        this.globalRoute.ptEntities.forEach((entity) => {
          // let gpslngLatTmp = coordtransform.wgs84togcj02(this.site.siteLocation[0], this.site.siteLocation[1]);
          let gpslngLatTmp1 = [entity.lng, entity.lat];
          let lng1 = gpslngLatTmp1[0],
            lat1 = gpslngLatTmp1[1];
          degreesArrayHeights.push(lng1, lat1, entity.alt);
        });
        this.globalRoute.lineEntity.position = Cartesian3.fromDegreesArrayHeights(degreesArrayHeights);

        //最后一个点虚线航线

        if (this.lineDashPrimitive && index == this.globalRoute.ptEntities.length - 1 && index != 0) {
          window.viewer.scene.remove(this.globalRoute.lineDashPrimitive);
          let siteHas = false,
            slng = 0,
            slat = 0,
            sAlt = 0;
          if (this.endSite && this.endSite.siteLocation) {
            siteHas = true;
            //  let gpslngLatS = coordtransform.wgs84togcj02(this.endSite.siteLocation[0], this.endSite.siteLocation[1]);
            let gpslngLatS = [this.endSite.siteLocation[0], this.endSite.siteLocation[1]];
            slng = gpslngLatS[0];
            slat = gpslngLatS[1];
            sAlt = this.endSite.siteAltitude;
          } else if (this.site && this.site.siteLocation) {
            siteHas = true;
            //  let gpslngLatS = coordtransform.wgs84togcj02(this.site.siteLocation[0], this.site.siteLocation[1]);
            let gpslngLatS = [this.site.siteLocation[0], this.site.siteLocation[1]];
            slng = gpslngLatS[0];
            slat = gpslngLatS[1];
            sAlt = this.site.siteAltitude;
          }

          if (siteHas) {
            let arrs = [slng, slat, sAlt, lng, lat, alt];
            this.globalRoute.lineDashPrimitive = window.viewer.scene.primitives.add(
              new Primitive({
                geometryInstances: new GeometryInstance({
                  geometry: new PolylineGeometry({
                    positions: Cartesian3.fromDegreesArrayHeights(arrs),
                    width: ROUTE.width,
                    // vertexFormat: PolylineColorAppearance.VERTEX_FORMAT,
                  }),
                }),
                appearance: new PolylineMaterialAppearance({
                  material: Material.fromType(Material.PolylineDashType, {
                    color: Color.YELLOW.withAlpha(CESIUM_PARAM.line_alpha), //线条颜色
                    gapColor: Color.TRANSPARENT, //间隔颜色
                    dashLength: 15, //短划线长度
                  }),
                }),
              }),
            );
            this.globalRoute.lineDashPrimitive.xl = true;
          }
        }
      } else {
        console.error(index, pt, this.rute.entities.length);
      }
    },

    //根据下标删除航点
    deleteRoutePtByIdex(index) {
      if (index >= 0 && index < this.globalRoute.ptEntities.length) {
        let lastPt = false;
        //是否需要更新虚线
        if (index == this.globalRoute.ptEntities.length - 1) {
          lastPt = true;
        }
        //点
        window.viewer.entities.remove(this.globalRoute.ptEntities[index]);
        this.globalRoute.entities.splice(index, 1);
        //地面线
        window.viewer.scene.remove(this.globalRoute.ptPrimitives[index]);
        this.globalRoute.ptPrimitives.splice(index, 1);

        // 更新主航线
        let degreesArrayHeights = [];
        // 第一个航点
        if (this.site && this.site.siteLocation) {
          // 经纬度转换
          // let gpslngLatTmp = coordtransform.wgs84togcj02(this.site.siteLocation[0], this.site.siteLocation[1]);
          let gpslngLatTmp = [this.site.siteLocation[0], this.site.siteLocation[1]];
          let slng = gpslngLatTmp[0],
            slat = gpslngLatTmp[1];
          degreesArrayHeights.push(slng, slat, this.site.siteAltitude);
        }
        this.globalRoute.ptEntities.forEach((entity) => {
          // let gpslngLatTmp = coordtransform.wgs84togcj02(this.site.siteLocation[0], this.site.siteLocation[1]);
          let gpslngLatTmp1 = [entity.lng, entity.lat];
          let lng1 = gpslngLatTmp1[0],
            lat1 = gpslngLatTmp1[1];
          degreesArrayHeights.push(lng1, lat1, entity.alt);
        });
        this.globalRoute.lineEntity.position = Cartesian3.fromDegreesArrayHeights(degreesArrayHeights);

        // 更新虚线
        if (this.globalRoute.lineDashPrimitive) {
          window.viewer.scene.remove(this.globalRoute.lineDashPrimitive);
          let siteHas = false,
            slng = 0,
            slat = 0,
            sAlt = 0;
          if (this.endSite && this.endSite.siteLocation) {
            siteHas = true;
            //  let gpslngLatS = coordtransform.wgs84togcj02(this.endSite.siteLocation[0], this.endSite.siteLocation[1]);
            let gpslngLatS = [this.endSite.siteLocation[0], this.endSite.siteLocation[1]];
            slng = gpslngLatS[0];
            slat = gpslngLatS[1];
            sAlt = this.endSite.siteAltitude;
          } else if (this.site && this.site.siteLocation) {
            siteHas = true;
            //  let gpslngLatS = coordtransform.wgs84togcj02(this.site.siteLocation[0], this.site.siteLocation[1]);
            let gpslngLatS = [this.site.siteLocation[0], this.site.siteLocation[1]];
            slng = gpslngLatS[0];
            slat = gpslngLatS[1];
            sAlt = this.site.siteAltitude;
          }
          if (siteHas) {
            let arrs = [slng, slat, sAlt, lng, lat, alt];
            this.globalRoute.lineDashPrimitive = window.viewer.scene.primitives.add(
              new Primitive({
                geometryInstances: new GeometryInstance({
                  geometry: new PolylineGeometry({
                    positions: Cartesian3.fromDegreesArrayHeights(arrs),
                    width: ROUTE.width,
                    // vertexFormat: PolylineColorAppearance.VERTEX_FORMAT,
                  }),
                }),
                appearance: new PolylineMaterialAppearance({
                  material: Material.fromType(Material.PolylineDashType, {
                    color: Color.YELLOW.withAlpha(CESIUM_PARAM.line_alpha), //线条颜色
                    gapColor: Color.TRANSPARENT, //间隔颜色
                    dashLength: 15, //短划线长度
                  }),
                }),
              }),
            );

            this.globalRoute.lineDashPrimitive.xl = true;
          }
        }
      }
    },

    //更新无人机位置
    /* data: {
      position:[lng,lat,ht],
      head,
      pitch,
      roll
    }
    */
    updateUavPos(data) {
      if (!data.position) {
        return;
      }
      if (data.position[0] <= 0 || data.position[1] <= 0) {
        // 清除无人机
        if (window.UAVModel) {
          window.viewer.entities.remove(window.UAVModel);
          window.UAVModel = null;
        }
        return;
      }
      console.log('updateUavPos');
      const position = Cartesian3.fromDegrees(...data.position);
      // M300 模型存在偏移
      const heading = Math.toRadians(data.head + M300GlbHeadOffset);
      const pitch = Math.toRadians(data.pitch);
      const roll = Math.toRadians(data.roll);
      const hpr = new HeadingPitchRoll(heading, pitch, roll);
      const orientation = Transforms.headingPitchRollQuaternion(position, hpr);
      if (!window.UAVModel) {
        window.UAVModel = window.viewer.entities.add({
          name: M300Glb,
          position: position,
          orientation: orientation,
          model: {
            uri: M300Glb, //
            minimumPixelSize: 120,
            maximumScale: 20000,
          },
        });
        window.UAVModel.xl = true;
      } else {
        window.UAVModel.position = position;
        window.UAVModel.orientation = orientation;
      }
    },

    // 更新无人机实时轨迹
    /* data:{
      lng,
      lat,

     }

     */
    setRealTimePath(arr) {
      let entity = window.viewer.entities.getById(ID.realTimePath);
      if (arr.length === 0 || arr.length === 1) {
        console.log(arr[0]);
        // celar
        entity && window.viewer.entities.remove(entity);
        entity = null;
        window.cesiumTrailPathArr &&
          window.cesiumTrailPathArr.forEach((en) => {
            window.viewer.entities.remove(en);
          });
        window.cesiumTrailPath = null;
        window.cesiumTrailPathArr = [];
      } else {
        let pos = [];
        arr.forEach((ar) => {
          pos.push(ar.lng, ar.lat, ar.alt);
        });

        if (entity) {
          //update
          let newcor = [pos[pos.length - 6], pos[pos.length - 5], pos[pos.length - 4], pos[pos.length - 3], pos[pos.length - 2], pos[pos.length - 1]];
          let enti = window.viewer.entities.add({
            id: ID.realTimePath + '_' + new Date().getTime(),
            name: ID.realTimePath + '_' + window.cesiumTrailPathArr.length,
            polyline: {
              positions: Cartesian3.fromDegreesArrayHeights(newcor),
              width: ROUTE.width / 2,
              material: Color.CHARTREUSE.withAlpha(1),
               depthFailMaterial:new PolylineDashMaterialProperty({
                  color:  Color.YELLOW.CHARTREUSE(0.5),
               })
            },
          });
          enti.xl = true;
          window.cesiumTrailPathArr.push(enti);
        } else {
          // set
          window.cesiumTrailPath = window.viewer.entities.add({
            id: ID.realTimePath,
            name: ID.realTimePath,
            polyline: {
              positions: Cartesian3.fromDegreesArrayHeights(pos),
              width: ROUTE.width / 2,
              material: Color.CHARTREUSE.withAlpha(1),
              depthFailMaterial:new PolylineDashMaterialProperty({
                  color:  Color.YELLOW.CHARTREUSE(0.5),
               })
            },
          });
          window.cesiumTrailPath.xl = true;
          window.cesiumTrailPathArr = [];
        }
      }
    },

    toColumbus(){
      if(window.viewer){
        this.sceneMode =this.sceneMode==1?3:1;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
$hilightColor: #36c6fb;
$color-layer-bg: rgba(18, 18, 18, 0.85);
$color-layer: #a1a1a1;
#cesiumContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .base-box {
    position: absolute;
    z-index: 400;
    right: 10px;
    bottom: 28px;
    transition: right 0.5s;
    .base-wrapper {
      position: relative;
      .base-layer-box {
        width: 100%;
      }
    }
    .common {
      cursor: pointer;
    }

    img {
      width: 28px;
      &.hilight {
        border-radius: 3px;
        border: 1px solid $hilightColor;
      }
    }
    .layer-box {
      background: $color-layer-bg;
      border: 1px solid #6a6a6a;
      border-radius: 5px;
      padding: 16px 0 10px;
      position: absolute;
      bottom: 35%;
      right: 54px;
      width: 142px;
      min-width: 126px;
      .switch-item {
        height: 40px;
        > div {
          width: 50%;
          float: left;
          color: white;
        }
      }
      .title {
        border-left: 2px solid $hilightColor;
        padding-left: 10px;
        color: #ffffff;
      }
      .tool-item {
        height: 20px;
        margin-top: 5px;
        padding: 0 15px;
        color: white;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        &.is-drawing {
          color: $hilightColor;
        }
        .spot {
          width: 10px;
          height: 10px;
          background: $hilightColor;
          border-radius: 50%;
          position: absolute;
          right: 15px;
          top: 4px;
        }
        &:hover {
          opacity: 0.8;
          background-color: #0b172f;
        }
        img {
          width: 14px;
          height: 14px;
        }
      }
      .layer-group {
        padding: 6px 0 6px 12px;
        .layer {
          display: block;
          padding: 6px 0;
        }
      }
      // 区域定位样式
      .layer.child {
        padding-left: 30px;
      }
      /deep/.ant-checkbox-wrapper {
        font-size: 14px;
      }
    }
  }
}
</style>
